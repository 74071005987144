/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Autocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@vds/icons';
import { linkVzTag } from '../../utils/unifiedVzTag';
import { dataTrackwithDetail, dataTrack } from '../../utils/vzdl';

const ReskinWrapper = styled.div`
  position: relative;
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  #reskin-internal-wrapper {
    width: 100%;
  }
  .ac-reskin-success input {
    padding-right: 3rem;
  }
  #ac-reskin-success-icon {
    display: ${(props) => (props.success ? 'block' : 'none')};
    position: absolute;
    top: 0.9rem;
    right: 1.5rem;
  }

  #ac-reskin-error-icon {
    position: absolute;
    top: 0.9rem;
    right: 1.5rem;
  }

  .ac-reskin-success input:focus {
  }

  @-webkit-keyframes loading {
    from {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    from {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner-loader {
    display: block !important;
  }
  .spinner-loaderhide input {
    border-bottom: ${(props) => props.success && '4px solid rgb(0, 131, 48)'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
  }
  .spinner-loader::after {
    content: '';
    position: absolute;
    display: block;
    right: 1rem;
    top: 0.6rem;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    background-image: url("data:image/svg+xml,%3Csvg stroke='%23000000' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9 16.9c-4.3 0-7.9-3.5-7.9-7.9s3.6-7.9 7.9-7.9v-1.1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9h-1.1c0 4.3-3.6 7.9-7.9 7.9z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    border-top-color: transparent;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: loading 0.5s infinite linear;
    animation: loading 0.5s infinite linear;
    display: block;
  }
`;

class AutoComplete extends React.PureComponent {
  componentDidMount() {
    const autocompleteInput = document.querySelector('.autocompleteInput');
    if (autocompleteInput) {
      // autocompleteInput.removeAttribute('role');
      // autocompleteInput.removeAttribute('aria-expanded');
      autocompleteInput.onkeydown = (e) => {
        this.onHandleKeydown(e);
      };
      autocompleteInput.onclick = (e) => {
        if (
          this.props.loopQualApp &&
          this.props.loopQualApp.lq4 &&
          e &&
          e.type &&
          e.type === 'click' &&
          e.which === 1 &&
          autocompleteInput.value === ''
        ) {
          linkVzTag('lq sticky bar - clicked', 'lq sticky bar');
        }
      };
      autocompleteInput.onfocus = () => {
        const { nextSibling } = autocompleteInput;
        if (nextSibling) {
          nextSibling.setAttribute('role', 'listbox');
          nextSibling.setAttribute('id', 'Addressoptions');
        }
        this.autocompletOptionsShown();
        this.removeAriaExpanded();
      };
      autocompleteInput.onblur = () => {
        this.removeAriaExpanded();
        const internalWrapperDiv = document.querySelector('#reskin-internal-wrapper');
        if (internalWrapperDiv) {
          // internalWrapperDiv.setAttribute('aria-expanded', 'false');
        }
        // const autocompleteInput = document.querySelector('.autocompleteInput');
        // autocompleteInput.setAttribute('aria-expanded', 'false');
      };
    }
    const unitAutocomplete = document.querySelector('[name="lqw-unit-num"]');
    if (unitAutocomplete) {
      unitAutocomplete.removeAttribute('role');
      unitAutocomplete.removeAttribute('aria-expanded');
      unitAutocomplete.onkeydown = (e) => {
        this.onHandleKeydown(e);
      };
      unitAutocomplete.onfocus = () => {
        const { nextSibling } = unitAutocomplete;
        if (nextSibling) {
          nextSibling.setAttribute('role', 'listbox');
          nextSibling.setAttribute('id', 'Unitoptions');
        }
        this.autocompleteUnitOptionsShown();
        this.removeAriaExpanded();
      };
      unitAutocomplete.onblur = () => {
        this.removeAriaExpanded();
        const internalWrapperDiv = document.querySelector('#internal-wrapper-unit');
        if (internalWrapperDiv) {
          internalWrapperDiv.setAttribute('aria-expanded', 'false');
        }
      };
    }
  }

  componentDidUpdate() {
    const body = document.querySelector('body');
    this.autocompletOptionsShown();
    const unitAutocomplete = document.querySelector('[name="lqw-unit-num"]');
    if (unitAutocomplete) {
      unitAutocomplete.onfocus = () => {
        unitAutocomplete.setAttribute('role', 'combobox');
        unitAutocomplete.removeAttribute('aria-autocomplete');
        unitAutocomplete.removeAttribute('aria-labelledby');
      };
    }
    if (body.classList.contains('hide-focus-outline')) {
      const validInputSuccessLoader = this.props.success;
      if (validInputSuccessLoader) {
        this.successLoaderIconFocus();
      }
    }

    // remove input icon title and area label for nvda
    if (this.props?.addressLookup?.streetError) {
      const errorSvgIcon = this.errorIconRef.querySelector('svg');
      const titleOfSvg = errorSvgIcon.querySelector('title');
      if (titleOfSvg) {
        titleOfSvg.remove();
      }
      if (errorSvgIcon.hasAttribute('aria-label')) {
        errorSvgIcon.removeAttribute('aria-label');
      }
    }
  }

  removeAriaExpanded = () => {
    const autocompleteInput = document.querySelector('.autocompleteInput');
    autocompleteInput.removeAttribute('aria-expanded');
  };

  autocompletOptionsShown = () => {
    const internalWrapperDiv = document.querySelector('#reskin-internal-wrapper');
    const listItemWrapper = document.querySelectorAll('.reskin-menu-list-item');
    const addressOptions = document.querySelector('#Addressoptions');
    const autocompleteInput = document.querySelector('.autocompleteInput');
    autocompleteInput.setAttribute('aria-activedescendant', 'selectedOption');
    if (internalWrapperDiv) {
      if (listItemWrapper.length > 0 && addressOptions) {
        internalWrapperDiv.setAttribute('aria-expanded', 'true');
        // autocompleteInput.setAttribute('aria-expanded', 'true');
        // autocompleteInput.setAttribute('role', 'combobox');
      } else {
        // internalWrapperDiv.setAttribute('aria-expanded', 'false');
        // autocompleteInput.setAttribute('aria-expanded', 'false');
        // autocompleteInput.setAttribute('role', 'combobox');
      }
    }
  };

  autocompleteUnitOptionsShown = () => {
    const internalWrapperDiv = document.querySelector('#internal-wrapper-unit');
    const listItemWrapper = document.querySelectorAll('.menu-list-item');
    const unitOptions = document.querySelector('#Unitoptions');

    if (internalWrapperDiv) {
      if (listItemWrapper.length > 0 && unitOptions) {
        internalWrapperDiv.setAttribute('aria-expanded', 'true');
      } else {
        internalWrapperDiv.setAttribute('aria-expanded', 'false');
      }
    }
  };

  successLoaderIconFocus = () => {
    const successLoaderIconDiv = document.querySelector('#ac-reskin-success-icon');
    const nextElement = successLoaderIconDiv.querySelector('svg');
    if (nextElement) {
      nextElement.setAttribute('aria-label', 'Checkmark icon - Address looks good');
      nextElement.setAttribute('alt', '');
      nextElement.setAttribute('tabindex', '-1');
      nextElement.setAttribute('aria-hidden', 'true');
    }
  };

  onHandleKeydown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      setTimeout(() => {
        const highlightedItem = document.getElementById('selectedOption');
        const itemValue = highlightedItem && (highlightedItem.querySelector('a').innerText || undefined);
        if (itemValue) {
          e.target.value = itemValue;
        }
      });
    }
  };

  getItemValue = (item) => {
    const inputval = this.props.inputProps.itemval;
    return item ? item[inputval] : '';
  };

  // getHighlightedString = (displayValue, matchingString) => reactStringReplace(displayValue, matchingString, (matchedString, i) => (
  //   <span style={{ fontWeight: 'bold' }} tabIndex="-1" role="button">{matchedString}</span>))

  getHighlightedString = (displayValue) => <>{displayValue}</>;

  renderItem = (item, isHighlighted) => {
    const { typedValue } = this.props;
    const inputval = this.props.inputProps.itemval;
    const inputkey = this.props.inputProps.itemkey.reduce((accumulator, currentItem, index) => {
      if (index === 1) {
        return `${item[accumulator]}-${item[currentItem]}`;
      }
      return `${accumulator}-${item[currentItem]}`;
    });
    const undefKey = item?.disprawstr !== null && item?.disprawstr !== undefined && this.props?.items[0].disprawstr === item?.disprawstr;
    const displayValue = item[inputval];
    const matchingString = displayValue && displayValue.indexOf(typedValue) > -1 ? typedValue : '';
    const highlightedText = this.getHighlightedString(displayValue, matchingString);
    const { datatracker } = this.props.inputProps;
    let unitExp = '';
    if (displayValue === 'I can’t find my unit' || displayValue === "I don't live in a unit") {
      unitExp = displayValue;
    } else if (displayValue === 'Need Help') {
      unitExp = 'need help select';
    } else if (displayValue === 'Show more results') {
      unitExp = 'see more addresses in dropdown';
    } else if (displayValue === 'Show less results') {
      unitExp = 'see less addresses in dropdown';
    }
    if (unitExp) {
      if (this.props.loopQualApp.lq4) {
        unitExp = dataTrackwithDetail('link', unitExp, 'lq sticky bar');
      } else {
        unitExp = dataTrack('link', unitExp);
      }
    }
    if (isHighlighted && !undefKey) {
      const selectedEleReskin = document.querySelector('#reskin-internal-wrapper')?.firstChild?.nextSibling;
      const selectedEle = document.querySelector('#Addressoptions');
      const parentEle = selectedEleReskin || selectedEle;

      if (parentEle && parentEle.firstChild) {
        parentEle.firstChild.classList.remove('menuHighlighted');
        parentEle.firstChild.id = '';
        parentEle.firstChild.firstChild.classList.remove('item-highlighted');
      }
    }
    return (
      <div
        key={inputkey}
        className={`reskin-menu-list-item ${isHighlighted ? 'menuHighlighted' : ''}`}
        // tabIndex="-1"
        id={isHighlighted ? 'selectedOption' : ''}
        role="option"
        aria-selected={isHighlighted ? 'true' : 'false'}
        aria-live="polite"
        aria-atomic="false"
      >
        <a style={{ fontWeight: 'bold' }} className={isHighlighted ? 'item-highlighted' : ''} data-track={unitExp || datatracker}>
          {highlightedText}
        </a>
      </div>
    );
  };

  render() {
    const validInput = this.props.success;
    const { showLoader, unitLookupError, addressLookupError } = this.props;
    // disabling the success as per UX suggestion
    const displaycheckmarkIcon = false;
    const acWrapperProps = {
      // id: 'internal-wrapper',
      id: 'reskin-internal-wrapper',
      // role: 'combobox',
      // 'aria-expanded': 'false',
      // 'aria-haspopup': 'listbox',
    };
    const isMonarchEnabled = true;
    const showErrorIcon = isMonarchEnabled;

    if (validInput) {
      acWrapperProps.className = 'ac-reskin-success';
    }
    if (this.props.unitInputError && !this.props.addressLookup.sameAddressError) {
      acWrapperProps.className += ' unitInputError';
    }

    return (
      <ReskinWrapper id="ac-reskin-wrapper" success={validInput}>
        <Autocomplete
          {...this.props}
          getItemValue={this.getItemValue}
          autoHighlight={false}
          renderItem={this.renderItem}
          wrapperProps={acWrapperProps}
        />
        <span className="visually-hidden" role="status" aria-label={`${this.props.items.length} suggested results`}>
          {`${this.props.items.length} suggested results`}
        </span>
        <div id="ac-reskin-success-icon">{!showLoader && displaycheckmarkIcon && <Icon name="checkmark-alt" tabIndex="-1" size="medium" />}</div>
        {(unitLookupError || addressLookupError) && !showLoader && showErrorIcon && (
          <div id="ac-reskin-error-icon" ref={(ref) => (this.errorIconRef = ref)}>
            <Icon name="error" ariaLabel="" tabIndex="-1" size="medium" />
          </div>
        )}
        {showLoader && <div className="spinner-loader" />}
      </ReskinWrapper>
    );
  }
}

const menuStyle = {
  borderWidth: '1px',
  borderColor: '#ccc',
  borderStyle: 'solid',
  overflow: 'auto',
  maxHeight: '8rem',
  borderRadius: '7px',
};

AutoComplete.defaultProps = {
  value: '',
  items: [],
  onChange() {},
  onSelect() {},
  menuStyle,
  inputProps: {},
};
AutoComplete.propTypes = {
  value: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  getItemValue: PropTypes.func,
  renderItem: PropTypes.func,
  onSelect: PropTypes.func,
  menuStyle: PropTypes.object,
  wrapperStyle: PropTypes.object,
  inputProps: PropTypes.object,
  success: PropTypes.any,
  unitLookupError: PropTypes.bool,
  addressLookupError: PropTypes.bool,
  typedValue: PropTypes.any,
  showLoader: PropTypes.any,
  loopQualApp: PropTypes.any,
  unitInputError: PropTypes.any,
  addressLookup: PropTypes.any,
};

export default AutoComplete;
