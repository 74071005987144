import callingApi from '../../services/apiService';
import { apiUrl } from '../../constants/api-urls';
import { MethodType, Pages, LQ_VIEWS } from '../../constants/common';
import { Fios as ActionTypes, PAGEACTIVITY, LoopQualification } from '../../constants/actionTypes';
import { updatePlansPage, updateCurrentView, updateValidCouponFlow } from '../loop-qual-app/actions';
import {
  redirectToPlansPage,
  redirectToFiosPlansPage,
  genarateToken,
  checkTokenValidity,
  redirectToInhomeTwsPage,
  setToStorage,
} from '../../utils/lqServiceUtil';
import { catchJSException, logPageDetailsInfo, logKafka, logMetrics, logToKibanna } from '../../services/logService';
import { showLoader, hideLoader } from '../common/loader/actions';
import { openViewVzTag } from '../../utils/unifiedVzTag';
import { setModalWindowTitle } from '../common/modal/action';

const getErrorLogUrl = apiUrl().kafkaErrorAppLogs;

export const existingService = (newService) => {
  try {
    const roomate = newService === 'Y' ? 'N' : 'Y';
    const data = { holdforworkingservice: newService, roomatescenario: roomate, moversIntent: newService };
    const url = apiUrl().fiosExistingService;
    const contentType = '';
    const requiredAuth = { authToken: true };
    if (!checkTokenValidity()) {
      genarateToken()
        .then((res) => {
          if (res.status === 200) {
            callingApi(url, MethodType.POST, data, contentType, requiredAuth)
              .then((response) => {
                console.log(response);
                logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'existingService', PAGEACTIVITY.MSG.fiosExistingServiceSuccess, PAGEACTIVITY.UA);
              })
              .catch((error) => {
                logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'existingService', PAGEACTIVITY.MSG.fiosExisitingSeviceFailure, PAGEACTIVITY.UA);
                const errorMsg = `MethodName:existingService,errorMessage:${error.message}`;
                logKafka('ERROR', 'existingService', errorMsg, getErrorLogUrl, 'applogs');
                logMetrics('existingService', error.message, 'Error');
              });
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'existingService', '', getErrorLogUrl, 'applogs');
          logMetrics('existingService', error.message, 'Error');
        });
    } else {
      callingApi(url, MethodType.POST, data, contentType, requiredAuth)
        .then((response) => {
          console.log(response);
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'existingService', PAGEACTIVITY.MSG.fiosExistingServiceSuccess, PAGEACTIVITY.UA);
        })
        .catch((error) => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'existingService', PAGEACTIVITY.MSG.fiosExisitingSeviceFailure, PAGEACTIVITY.UA);
          const errorMsg = `MethodName:existingService,errorMessage:${error.message}`;
          logKafka('ERROR', 'existingService', errorMsg, getErrorLogUrl, 'applogs');
          logMetrics('existingService', error.message, 'Error');
        });
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'existingService', error);
    logMetrics('existingService', error.message, 'Error');
  }
};

export const processSaveCart = (dispatch, customerStreetDetails, scEmail, scMtn) => {
  try {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            invokeProcessSaveCart(dispatch, customerStreetDetails, scEmail, scMtn);
          }
        })
        .catch((error) => {
          dispatch(hideLoader());
          logKafka('ERROR', 'processSaveCart', error, getErrorLogUrl, 'applogs');
          logMetrics('processSaveCart', error.message, 'Error');
        });
    } else {
      invokeProcessSaveCart(dispatch, customerStreetDetails, scEmail, scMtn);
    }
  } catch (error) {
    dispatch(hideLoader());
    catchJSException('LOOPQUAL2.0', 'processSaveCart', error);
    logMetrics('processSaveCart', error.message, 'Error');
  }
};

export const invokeProcessSaveCart = (dispatch, customerStreetDetails, scEmail, scMtn) => {
  const data = {
    addressID: customerStreetDetails ? customerStreetDetails.ntasAddrID || customerStreetDetails.locusID : '',
    email: scEmail || '',
    state: customerStreetDetails ? customerStreetDetails.state : '',
    mtn: scMtn || '',
    client: 'VZCOM',
    zip: customerStreetDetails ? customerStreetDetails.zip : '',
  };
  dispatch(showLoader());
  callingApi(apiUrl().fiosProcessSaveCart, MethodType.POST, data, '', { authToken: true })
    .then((response) => {
      dispatch(hideLoader());
      logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'processSaveCart', PAGEACTIVITY.MSG.processSaveCartSuccess, PAGEACTIVITY.UA);
      if (response && response.data && response.data.data) {
        dispatch({ type: ActionTypes.SAVE_RESPONSE, value: response.data.data });
        updateResumeCartView(dispatch, true);
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'processSaveCart', PAGEACTIVITY.MSG.processSaveCartFailure, PAGEACTIVITY.UA);
      const errorMsg = `MethodName:processSaveCart,errorMessage:${error.message}`;
      logKafka('ERROR', 'invokeProcessSaveCart', errorMsg, getErrorLogUrl, 'applogs');
      logMetrics('invokeProcessSaveCart', error.message, 'Error');
    });
};

export const sendSmsOrEmail = (dispatch, mon, smsOrEmail, isMtn, cartCreateDate) => {
  try {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            invokeSendSmsOrEmail(dispatch, mon, smsOrEmail, isMtn, cartCreateDate);
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'sendSmsOrEmail', error, getErrorLogUrl, 'applogs');
          logMetrics('sendSmsOrEmail', error.message, 'Error');
        });
    } else {
      invokeSendSmsOrEmail(dispatch, mon, smsOrEmail, isMtn, cartCreateDate);
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'sendSmsOrEmail', error);
    logMetrics('sendSmsOrEmail', error.message, 'Error');
  }
};
export const invokeSendSmsOrEmail = (dispatch, mon, smsOrEmail, isMtn, cartCreateDate) => {
  const data = {
    email: !isMtn ? smsOrEmail : '',
    mtn: isMtn ? smsOrEmail : '',
    mon,
    deliveryType: isMtn ? 'SMS' : 'EMAIL',
    cartCreateDate,
  };
  callingApi(apiUrl().fiosSendSmsOrEmail, MethodType.POST, data, '', { authToken: true })
    .then((response) => {
      logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'sendSmsOrEmail', PAGEACTIVITY.MSG.sendSmsOrEmailSuccess, PAGEACTIVITY.UA);
      if (response && response.meta && response.meta.code && response.meta.code.toString().indexOf('200') > -1) {
        dispatch({ type: ActionTypes.SENT_SMSOREMAIL, value: true });
      }
    })
    .catch((error) => {
      logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'sendSmsOrEmail', PAGEACTIVITY.MSG.sendSmsOrEmailFailure, PAGEACTIVITY.UA);
      const errorMsg = `MethodName:sendSmsOrEmail,errorMessage:${error.message}`;
      logKafka('ERROR', 'sendSmsOrEmail', errorMsg, getErrorLogUrl, 'applogs');
      logMetrics('invokeSendSmsOrEmail', error.message, 'Error');
    });
};
export const updateCouponLQ = (value) => ({ type: LoopQualification.UPDATE_COMMON_LQ, value });

export const cancelSmartcart = (dispatch, resumeMon, isMovers, loopQualApp, fiosDetails, lqResult) => {
  try {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            invokeCancelSmartcart(dispatch, resumeMon, isMovers, loopQualApp, fiosDetails, lqResult);
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'cancelSmartcart', error, getErrorLogUrl, 'applogs');
          logMetrics('cancelSmartcart', error.message, 'Error');
        });
    } else {
      invokeCancelSmartcart(dispatch, resumeMon, isMovers, loopQualApp, fiosDetails, lqResult);
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'cancelSmartcart', error);
    logMetrics('cancelSmartcart', error.message, 'Error');
  }
};
export const invokeCancelSmartcart = (dispatch, resumeMon, isMovers, loopQualApp, fiosDetails, lqResult) => {
  try {
    const url = `${apiUrl().fiosSmartCartCancel}?mon=${resumeMon}&isLQResumeCancelled=Y`;
    callingApi(url, MethodType.GET, '', '', { authToken: true })
      .then((response) => {
        console.log(response);
        logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'cancelSmartcart', PAGEACTIVITY.MSG.cancelSmartcartSuccess, PAGEACTIVITY.UA);
        if (loopQualApp.TWS) {
          redirectToInhomeTwsPage(loopQualApp.TWS, dispatch);
        } else if (fiosDetails.vendorDetails && fiosDetails.vendorDetails.vendor_name) {
          redirectToFiosPlansPage(Pages.PartnerInHomeBuildProduct, dispatch);
        } else if (window.location.host.includes('.verizonwireless.com')) {
          let redirectUrl = '';
          if (loopQualApp.mhToken) {
            redirectUrl = `https://www98.verizon.com${Pages.InHomeBuildProduct}&visitor_id=${lqResult.visitor_id}&visit_id=${lqResult.visit_id}&vzt=${loopQualApp.mhToken}`;
          } else {
            redirectUrl = `https://www98.verizon.com${Pages.InHomeBuildProduct}&visitor_id=${lqResult.visitor_id}&visit_id=${lqResult.visit_id}`;
          }
          redirectToFiosPlansPage(redirectUrl, dispatch);
        } else if (loopQualApp.mhToken) {
          const redirectUrl = `${Pages.InHomeBuildProduct}&vzt=${loopQualApp.mhToken}`;
          dispatch(updatePlansPage(redirectUrl));
        } else {
          const redirectUrl = isMovers && loopQualApp.isLoggedInUserInfo ? Pages.InHomeGetMoverDetail : Pages.InHomeBuildProduct;

          redirectToFiosPlansPage(redirectUrl, dispatch);
        }
      })
      .catch((error) => {
        logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'cancelSmartcart', PAGEACTIVITY.MSG.cancelSmartcartFailure, PAGEACTIVITY.UA);
        const errorMsg = `MethodName:cancelSmartcart,errorMessage:${error.message}`;
        logKafka('ERROR', 'cancelSmartcart', errorMsg, getErrorLogUrl, 'applogs');
        logMetrics('invokeCancelSmartcart', error.message, 'Error');
      });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'cancelSmartcart', error);
    logMetrics('invokeCancelSmartcart', error.message, 'Error');
  }
};

export const getVendorDetails = (dispatch) => {
  try {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            return InvokeGetVendorDetails(dispatch);
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'getVendorDetails', error, getErrorLogUrl, 'applogs');
          logMetrics('getVendorDetails', error.message, 'Error');
        });
    } else {
      return InvokeGetVendorDetails(dispatch);
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'getvendorvisitdetails', error);
    logMetrics('getVendorDetails', error.message, 'Error');
  }
};

export const InvokeGetVendorDetails = async (dispatch, isRetry = false) => {
  try {
    return new Promise((resolve) => {
      callingApi(apiUrl().getvendorvisitdetails, MethodType.GET, '', '', { authToken: true })
        .then(async (response) => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'getvendorvisitdetails', PAGEACTIVITY.MSG.getvendorvisitdetailsSuccess, PAGEACTIVITY.UA);
          if (response && response.data.meta && response.data.meta.code && response.data.meta.code.toString().indexOf('200') > -1) {
            if (response.data.data && response.data.data.vendordetails && response.data.data.vendordetails.vendor_name !== '') {
              const vendordetails = {
                vendor_name: response.data.data.vendordetails.vendorSalesDetails
                  ? response.data.data.vendordetails.vendorSalesDetails.vendor_name
                  : response.data.data.vendordetails.vendor_name,
                vendorId:
                  response.data.data.vendordetails.vendorSalesDetails && response.data.data.vendordetails.vendorSalesDetails.primarysalesid
                    ? response.data.data.vendordetails.vendorSalesDetails.primarysalesid
                    : '',
                locationCode:
                  response.data.data.vendordetails.vendorSalesDetails && response.data.data.vendordetails.vendorSalesDetails.locationCode
                    ? response.data.data.vendordetails.vendorSalesDetails.locationCode
                    : '',
                outletid:
                  response.data.data.vendordetails.vendorSalesDetails && response.data.data.vendordetails.vendorSalesDetails.outletid
                    ? response.data.data.vendordetails.vendorSalesDetails.outletid
                    : '',
                cmpid:
                  response.data.data.vendordetails.vendorSalesDetails && response.data.data.vendordetails.vendorSalesDetails.cmpid
                    ? response.data.data.vendordetails.vendorSalesDetails.cmpid
                    : '',
              };
              await updateVendorDetails(vendordetails, dispatch);
              resolve(true);
            } else if (!isRetry) {
              InvokeGetVendorDetails(dispatch, true);
              resolve(true);
            }
          }
        })
        .catch((error) => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'getvendorvisitdetails', PAGEACTIVITY.MSG.getvendorvisitdetailsFailure, PAGEACTIVITY.UA);
          const errorMsg = `MethodName:getvendorvisitdetails,errorMessage:${error.message}`;
          logKafka('ERROR', 'getvendorvisitdetails', errorMsg, getErrorLogUrl, 'applogs');
          logMetrics('InvokeGetVendorDetails', error.message, 'Error');
          resolve(true);
        });
    });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'getvendorvisitdetails', error);
    logMetrics('InvokeGetVendorDetails', error.message, 'Error');
  }
};

export const validateToken = (coupon, dispatch) => {
  try {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((res) => {
          if (res.status === 200) {
            invokeValidateToken(coupon, dispatch);
          }
        })
        .catch((error) => {
          dispatch(hideLoader());
          logKafka('ERROR', 'validateTokenLoopqual', '', getErrorLogUrl, 'applogs');
          logMetrics('validateToken', error.message, 'Error');
          return false;
        });
    } else {
      invokeValidateToken(coupon, dispatch);
    }
  } catch (ex) {
    dispatch(hideLoader());
    logKafka('ERROR', 'validateTokenLoopqual', ex, getErrorLogUrl, 'applogs');
    logMetrics('validateToken', ex.message, 'Error');
    return false;
  }
};
export const setVendorCookie = async (visitId, visitorId, dispatch) => {
  try {
    const data = {
      other_visit_id: visitId,
      other_visitor_id: visitorId,
      other_GSM_ID: '',
    };
    callingApi(apiUrl().generatevisitidforvendor, MethodType.POST, JSON.stringify(data), '', { authToken: true })
      .then(async (response) => {
        if (response && response.data && response.data.data) {
          const { visit_id, visitor_id, expirationTime } = response.data.data;
          window.localStorage.setItem('visit_id', visit_id);
          window.localStorage.setItem('visitor_id', visitor_id);
          window.localStorage.setItem('expirationTime', expirationTime);
          window.sessionStorage.setItem('visit_id', visit_id);
          window.sessionStorage.setItem('visitor_id', visitor_id);
          if (dispatch) {
            await InvokeGetVendorDetails(dispatch);
          }
        }
      })
      .catch((error) => {
        logKafka('ERROR', 'setVendorCookie', error, getErrorLogUrl, 'applogs');
        logMetrics('setVendorCookie', error.message, 'Error');
      });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'setVendorCookie', error);
    logMetrics('setVendorCookie', error.message, 'Error');
  }
};
const vendorRequest = (data) => {
  const request = {
    leadListDetails: {
      doFlow: false,
      intent: '',
      visitId: '',
      visitorId: '',
    },
    vendorQueryDetails: data,
  };
  return JSON.stringify(request);
};
export const invokeValidatevendor = (data, dispatch) => {
  try {
    callingApi(apiUrl().vendorFlow, MethodType.POST, vendorRequest(data), '', { authToken: true })
      .then((response) => {
        if (response && response.data.meta && response.data.meta.code && response.data.meta.code.toString().indexOf('200') > -1) {
          if (response.data.data && response.data.data.visitid && response.data.data.visitorid) {
            const { visitid, visitorid } = response.data.data;
            setVendorCookie(visitid, visitorid, dispatch);
          }
        }
      })
      .catch((error) => {
        logKafka('ERROR', 'vendorflowvalidate', error, getErrorLogUrl, 'applogs');
        logMetrics('vendorflowvalidate', error.message, 'Error');
      });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'vendorflowvalidate', error);
    logMetrics('vendorflowvalidate', error.message, 'Error');
  }
};
export const validateVendor = (data, dispatch) => {
  try {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            invokeValidatevendor(data, dispatch);
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'validateVendor', error, getErrorLogUrl, 'applogs');
          logMetrics('validateVendor', error.message, 'Error');
        });
    } else {
      invokeValidatevendor(data, dispatch);
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'validateVendordetails', error);
    logMetrics('validateVendor', error.message, 'Error');
  }
};

export const invokeValidateToken = (coupon, dispatch) => {
  try {
    setToStorage('couponToken', coupon); // temperory setting couponToken
    callingApi(apiUrl().validateCouponToken, MethodType.POST, JSON.stringify({ customertoken: coupon }), '', { authToken: true })
      .then((response) => {
        if (response.data && response.data.data) {
          dispatch(hideLoader());
          if (
            (response.data.data.result.status === 'Valid' && response.data.data.result.msg === 'User Token is valid') ||
            (window.location.search && window.location.search.toLowerCase().indexOf('testcoupon=y') > -1)
          ) {
            dispatch(updateCurrentView(LQ_VIEWS.COMMON_LQ));
            setToStorage('couponToken', response.data.data.customerToken ? response.data.data.customerToken : '');
            setToStorage('couponType', response.data.data.flowType ? response.data.data.flowType : '');
            dispatch(updateValidCouponFlow(true));
            setToStorage('couponInvoked', true);
            if (
              window.location.search &&
              window.location.search.toLowerCase().indexOf('testfwa=y') > -1 &&
              (window.location.host.includes('ebiz.verizon.com') || window.location.host.includes('98.verizon.com'))
            ) {
              window.location.href = `https://vzwqa3.verizonwireless.com/sales/home/external/checkAvailability.html?PN=${coupon}`;
            }
          } else if (
            (response.data.data.result.status === 'InValid' && response.data.data.result.msg.indexOf('expired') > -1) ||
            response.data.data.result.msg.indexOf('User Token is not valid') > -1
          ) {
            const redirectUrl = Pages.conflictMessage;
            redirectToPlansPage(redirectUrl);
            dispatch(updateValidCouponFlow(false));
          }
        } else {
          dispatch(hideLoader());
          logMetrics('updateValidCouponFlow', JSON.stringify(response), 'Info');
        }
        return response;
      })
      .catch((error) => {
        dispatch(hideLoader());
        const errorMsg = `MethodName:validateToken,errorMessage:${error.message}`;
        logKafka('ERROR', 'validateTokenLoopqual', errorMsg, getErrorLogUrl, 'applogs');
        logMetrics('invokeValidateToken', error.message, 'Error');
        return false;
      });
  } catch (ex) {
    dispatch(hideLoader());
    logKafka('ERROR', 'validateTokenLoopqual', ex, getErrorLogUrl, 'applogs');
    logMetrics('invokeValidateToken', ex.message, 'Error');
    return false;
  }
};

export const callValidateAPI = () => {
  if (!checkTokenValidity()) {
    genarateToken()
      .then((resp) => {
        if (resp.status === 200) {
          return invokeCallValidateAPI();
        }
      })
      .catch((error) => {
        logKafka('ERROR', 'callValidateAPI', error, getErrorLogUrl, 'applogs');
        logMetrics('callValidateAPI', error.message, 'Error');
      });
  } else {
    return invokeCallValidateAPI();
  }
};

export const invokeCallValidateAPI = async () => {
  try {
    return new Promise((resolve) => {
      callingApi(apiUrl().ssoMHAuthiniticate, MethodType.POST, { skip: 'Y' }, '', { authToken: true })
        .then(() => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'callValidateAPI_SUCCESS', 'callValidateAPI_SUCCESS', PAGEACTIVITY.UA);
          resolve(true);
        })
        .catch((error) => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'callValidateAPI_Failure', PAGEACTIVITY.MSG.callValidateAPIFailure, PAGEACTIVITY.UA);
          const errorMsg = `MethodName:callValidateAPI,errorMessage:${error.message}`;
          logKafka('ERROR', 'callValidateAPI_Failure', errorMsg, getErrorLogUrl, 'applogs');
          logMetrics('invokeCallValidateAPI', error.message, 'Error');
          resolve(true);
        });
    });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'callValidateAPI', error);
    logMetrics('invokeCallValidateAPI', error.message, 'Error');
  }
};

export const callVerificationAPI = (fiosDetails) => {
  if (!checkTokenValidity()) {
    genarateToken()
      .then((resp) => {
        if (resp.status === 200) {
          return invokeCallVerification(fiosDetails);
        }
      })
      .catch((error) => {
        logKafka('ERROR', 'callVerificationAPI', error, getErrorLogUrl, 'applogs');
        logMetrics('callVerificationAPI', error.message, 'Error');
      });
  } else {
    return invokeCallVerification(fiosDetails);
  }
};

export const invokeCallVerification = async (fiosDetails) => {
  try {
    const url = `${apiUrl().verification}?transactionID=${fiosDetails.validateSignInResponse.transactionID}`;
    return new Promise((resolve) => {
      callingApi(url, MethodType.GET, '', '', { authToken: true })
        .then(() => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'callVerificationAPI', 'callVerificationAPI_SUCCESS', PAGEACTIVITY.UA);
          logToKibanna('Mnh IG invokeCallVerification success', 'log');
          resolve(true);
        })
        .catch((error) => {
          logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'callVerificationAPI', 'callVerificationAPI_FAILURE', PAGEACTIVITY.UA);
          const errorMsg = `MethodName:callVerificationAPI,errorMessage:${error.message}`;
          logKafka('ERROR', 'callVerificationAPI_Failure', errorMsg, getErrorLogUrl, 'applogs');
          logMetrics('invokeCallVerification', error.message, 'Error');
          logToKibanna(`${error.message} Mnh IG invokeCallVerification`, 'log');
          resolve(true);
        });
    });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'callVerificationAPI', error);
    logMetrics('invokeCallVerification', error.message, 'Error');
    logToKibanna(`${error.message} Mnh IG invokeCallVerification`, 'log');
  }
};

export const callCheckPreLink = async () => {
  try {
    const url = apiUrl().checkPreLink;
    const data = '';
    const contentType = '';
    const requiredAuth = { authToken: true };
    if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            return new Promise((resolve) => {
              callingApi(url, MethodType.GET, data, contentType, requiredAuth)
                .then(() => {
                  logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'checkPreLink', PAGEACTIVITY.MSG.callCheckPrelinkAPISuccess, PAGEACTIVITY.UA);
                  logToKibanna('Mnh callCheckPreLink Success', 'log');
                  resolve(true);
                })
                .catch((error) => {
                  logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'checkPreLink', PAGEACTIVITY.MSG.callCheckPrelinkAPIFailure, PAGEACTIVITY.UA);
                  const errorMsg = `MethodName:checkPreLink,errorMessage:${error.message}`;
                  logKafka('ERROR', 'checkPreLink', errorMsg, getErrorLogUrl, 'applogs');
                  logMetrics('callCheckPreLink', error.message, 'Error');
                  logToKibanna(`${error.message} Mnh callCheckPreLink failed`, 'log');
                  resolve(true);
                });
            });
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'checkPreLink', error, getErrorLogUrl, 'applogs');
          logToKibanna(`${error.message} Mnh callCheckPreLink failed`, 'log');
          logMetrics('callCheckPreLink', error.message, 'Error');
        });
    } else {
      return new Promise((resolve) => {
        callingApi(url, MethodType.GET, data, contentType, requiredAuth)
          .then(() => {
            logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'checkPreLink', PAGEACTIVITY.MSG.callCheckPrelinkAPISuccess, PAGEACTIVITY.UA);
            logToKibanna('Mnh callCheckPreLink Success', 'log');
            resolve(true);
          })
          .catch((error) => {
            logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'checkPreLink', PAGEACTIVITY.MSG.callCheckPrelinkAPIFailure, PAGEACTIVITY.UA);
            const errorMsg = `MethodName:checkPreLink,errorMessage:${error.message}`;
            logKafka('ERROR', 'checkPreLink', errorMsg, getErrorLogUrl, 'applogs');
            logToKibanna(`${error.message} Mnh callCheckPreLink failed`, 'log');
            logMetrics('callCheckPreLink', error.message, 'Error');
            resolve(true);
          });
      });
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'checkPreLink', error);
    logToKibanna(`${error.message} Mnh callCheckPreLink failed`, 'log');
    logMetrics('callCheckPreLink', error.message, 'Error');
  }
};

export const signinMH = (
  dispatch,
  userId,
  pwd,
  lqResult,
  fiosDetails,
  staticContent,
  isMovers,
  zipcode,
  formattedMtn,
  loopQualApp,
  customerStreetDetails,
  customerUnitDetails,
) => {
  if (!checkTokenValidity()) {
    genarateToken()
      .then((resp) => {
        if (resp.status === 200) {
          invokeSiginMH(
            dispatch,
            userId,
            pwd,
            lqResult,
            fiosDetails,
            staticContent,
            isMovers,
            zipcode,
            formattedMtn,
            loopQualApp,
            customerStreetDetails,
            customerUnitDetails,
          );
        }
      })
      .catch((error) => {
        logKafka('ERROR', 'signinMH', error, getErrorLogUrl, 'applogs');
        logMetrics('signinMH', error.message, 'Error');
      });
  } else {
    invokeSiginMH(
      dispatch,
      userId,
      pwd,
      lqResult,
      fiosDetails,
      staticContent,
      isMovers,
      zipcode,
      formattedMtn,
      loopQualApp,
      customerStreetDetails,
      customerUnitDetails,
    );
  }
};

export const invokeSiginMH = (
  dispatch,
  userId,
  pwd,
  lqResult,
  fiosDetails,
  staticContent,
  isMovers,
  zipcode,
  formattedMtn,
  loopQualApp,
  customerStreetDetails,
  customerUnitDetails,
) => {
  try {
    let addrId = '';
    if (customerUnitDetails && customerUnitDetails.ntasAddrId) {
      addrId = customerUnitDetails.ntasAddrId.toString();
    } else if (customerStreetDetails && customerStreetDetails.addressID) {
      addrId = customerStreetDetails.addressID.toString();
    } else if (customerStreetDetails.ntasAddrID) {
      addrId = customerStreetDetails.ntasAddrID.toString();
    }

    const request = {
      mtn: userId,
      addressId: addrId,
    };
    dispatch(showLoader());
    callingApi(apiUrl().vzwOfferEligibility, MethodType.POST, request, '', { authToken: true })
      .then((resp) => {
        dispatch(hideLoader());
        logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'vzwOfferEligibility_Success', PAGEACTIVITY.MSG.vzwOfferEligibilitySuccess, PAGEACTIVITY.UA);
        if (resp && resp.data && resp.data.meta && resp.data.meta && resp.data.meta.code && resp.data.meta.code.toString().indexOf('200') > -1) {
          if (
            resp.data.data &&
            resp.data.data.offerEligibilitydata &&
            resp.data.data.offerEligibilitydata.validVzw &&
            resp.data.data.offerEligibilitydata.validVzw.toUpperCase() === 'Y' &&
            resp.data.data.offerEligibilitydata.accountOwnerManagerIndicator &&
            resp.data.data.offerEligibilitydata.accountOwnerManagerIndicator.toUpperCase() === 'Y'
          ) {
            const data = { username: userId, zipCode: zipcode };
            dispatch(showLoader());
            dispatch(updateMHEligble(true));
            callingApi(apiUrl().ssoMHAuthiniticate, MethodType.POST, data, '', { authToken: true })
              .then((response) => {
                dispatch(hideLoader());
                logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'signinMH_Success', PAGEACTIVITY.MSG.signinMHSuccess, PAGEACTIVITY.UA);
                if (response && response.data && response.data.data && response.data.data.isAuthenticated === 'Y') {
                  if (
                    response.data.data.tokenGenerationResponse &&
                    response.data.data.tokenGenerationResponse.transactionID &&
                    response.data.data.tokenGenerationResponse.data.listOfDevices &&
                    response.data.data.tokenGenerationResponse.data.listOfDevices[0] &&
                    response.data.data.tokenGenerationResponse.data.listOfDevices[0].deviceID &&
                    response.data.data.tokenGenerationResponse.data.listOfDevices[0].deviceType
                  ) {
                    if (
                      response.data.data.tokenGenerationResponse.data.listOfDevices[0].deviceType === 'SMARTPHONE' ||
                      response.data.data.tokenGenerationResponse.data.listOfDevices[0].deviceType === 'TABLET'
                    ) {
                      // updateSigninSuccess(dispatch, true);
                      logToKibanna(`Mnh IG authentication transactionID ${response.data.data.tokenGenerationResponse.transactionID}`, 'log');
                      logToKibanna('Mnh Smartphone IG authentication view', 'log');
                      dispatch(updateValidateResponse(response.data.data.tokenGenerationResponse));
                      const title = `We've sent a notification to ***.***.${formattedMtn.substr(formattedMtn.length - 4)}`;
                      openViewVzTag('notification modal', true);
                      setModalWindowTitle(title, dispatch);
                      updateSigninVerificationView(dispatch, true);
                      updateSigninSuccess(dispatch, true);
                      updateSigninView(dispatch, false);
                      updateFiosQualifiedView(dispatch, false);
                    } else if (response.data.data.tokenGenerationResponse.data.listOfDevices[0].deviceType === 'BASIC') {
                      logToKibanna(`Mnh IG authentication transactionID ${response.data.data.tokenGenerationResponse.transactionID}`, 'log');
                      logToKibanna('Mnh Basic IG authentication view', 'log');
                      dispatch(updateValidateResponse(response.data.data.tokenGenerationResponse));
                      const modalTitle = staticContent.ORDER_FIOS.BASIC_PHONE_TITLE;
                      openViewVzTag('otp verification overlay', true);
                      setModalWindowTitle(modalTitle, dispatch);
                      updateSigninMHBasicPhoneVerificationView(dispatch, true);
                      updateSigninSuccess(dispatch, true);
                      updateSigninVerificationView(dispatch, false);
                      updateSigninView(dispatch, false);
                      updateFiosQualifiedView(dispatch, false);
                    } else {
                      logToKibanna(
                        `Mnh ${response.data.data.tokenGenerationResponse.data.listOfDevices[0].deviceType} IG authentication view`,
                        'log',
                      );
                    }
                  } else {
                    logToKibanna('No Mnh IG authentication view', 'log');
                    if (lqResult.fiosQualification && lqResult.fiosQualification.inService === 'Y' && !loopQualApp.isACPFlow) {
                      updateInServiceView(dispatch, true);
                      updateFiosQualifiedView(dispatch, false);
                      openViewVzTag('existing service');
                      logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'existingService', PAGEACTIVITY.MSG.orderNow, PAGEACTIVITY.UA);
                      setModalWindowTitle(staticContent.ORDER_FIOS.IN_SERVICE.HEADER_INSERVICE, dispatch);
                    }
                    // else if (
                    //   lqResult.fiosQualification &&
                    //   lqResult.fiosQualification.smartCartDetails &&
                    //   lqResult.fiosQualification.smartCartDetails.smartCartAvailable &&
                    //   fiosDetails.saveResponse !== null &&
                    //   fiosDetails.saveResponse.cartRetrieved &&
                    //   fiosDetails.saveResponse.addressLine1 &&
                    //   fiosDetails.saveResponse.mon
                    // ) {
                    //   setModalWindowTitle(staticContent.ORDER_FIOS.RESUME_CART.HEADER_RESUMECART, dispatch);
                    //   updateResumeCartView(dispatch, true);
                    //   updateFiosQualifiedView(dispatch, false);
                    //   openViewVzTag('smart cart resume entry - continue your cart');
                    //   logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'resumeCart', PAGEACTIVITY.MSG.orderNow, PAGEACTIVITY.UA);
                    // }
                    else if (loopQualApp.TWS) {
                      redirectToInhomeTwsPage(loopQualApp.TWS, dispatch);
                    } else if (fiosDetails.vendorDetails && fiosDetails.vendorDetails.vendor_name) {
                      redirectToFiosPlansPage(Pages.PartnerInHomeBuildProduct, dispatch);
                    } else if (window.location.host.includes('.verizonwireless.com')) {
                      let redirectUrl = '';
                      if (loopQualApp.mhToken) {
                        redirectUrl = `https://www98.verizon.com${Pages.InHomeBuildProduct}&visitor_id=${lqResult.visitor_id}&visit_id=${lqResult.visit_id}&vzt=${loopQualApp.mhToken}`;
                      } else {
                        redirectUrl = `https://www98.verizon.com${Pages.InHomeBuildProduct}&visitor_id=${lqResult.visitor_id}&visit_id=${lqResult.visit_id}`;
                      }
                      redirectToFiosPlansPage(redirectUrl, dispatch);
                    } else if (loopQualApp.mhToken) {
                      const redirectUrl = `${Pages.InHomeBuildProduct}&vzt=${loopQualApp.mhToken}`;
                      redirectToFiosPlansPage(redirectUrl, dispatch);
                    } else {
                      const redirectUrl = isMovers && loopQualApp.isLoggedInUserInfo ? Pages.InHomeGetMoverDetail : Pages.InHomeBuildProduct;
                      redirectToFiosPlansPage(redirectUrl, dispatch);
                    }
                    dispatch({ type: ActionTypes.IS_LOGIN_AUTHENTICATED, value: true });
                  }
                } else {
                  dispatch({ type: ActionTypes.ERROR_SIGNIN_MH, value: true });
                  logToKibanna('Mnh IG isAuthenticated Not Y', 'log');
                }
              })
              .catch((error) => {
                dispatch(hideLoader());
                dispatch({ type: ActionTypes.ERROR_SIGNIN_MH, value: true });
                logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'signinMH_Failure', PAGEACTIVITY.MSG.signinMHFailure, PAGEACTIVITY.UA);
                const errorMsg = `MethodName:signinMH,errorMessage:${error.message}`;
                logKafka('ERROR', 'signinMH_Failure', errorMsg, getErrorLogUrl, 'applogs');
                logMetrics('invokeSiginMH', error.message, 'Error');
                logToKibanna(`Error in Mnh IG isAuthenticated ${error.message}`, 'error');
              });
          } else {
            dispatch({ type: ActionTypes.ERROR_SIGNIN_MH, value: true });
            logToKibanna('Mnh IG isAuthenticated Not Y', 'log');
          }
        } else {
          dispatch({ type: ActionTypes.ERROR_SIGNIN_MH, value: true });
          logToKibanna('Mnh IG isAuthenticated Not Y', 'log');
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: ActionTypes.ERROR_SIGNIN_MH, value: true });
        logPageDetailsInfo(PAGEACTIVITY.PAGE.LQ2, 'vzwOfferEligibility_failure', PAGEACTIVITY.MSG.vzwOfferEligibilityFailure, PAGEACTIVITY.UA);
        const errorMsg = `MethodName:signinMH,errorMessage:${error.message}`;
        logKafka('ERROR', 'vzwOfferEligibility_failure', errorMsg, getErrorLogUrl, 'applogs');
        logMetrics('invokeSiginMH', error.message, 'Error');
        logToKibanna(`Error in Mnh IG isAuthenticated ${error.message}`, 'error');
      });
  } catch (error) {
    dispatch(hideLoader());
    dispatch({ type: ActionTypes.ERROR_SIGNIN_MH, value: true });
    catchJSException('LOOPQUAL2.0', 'signinMH', error);
    logMetrics('invokeSiginMH', error.message, 'Error');
    logToKibanna(`Error in Mnh IG isAuthenticated invokeSiginMH ${error.message}`, 'error');
  }
};

export const updateFiosQualifiedView = (dispatch, input) => {
  dispatch({ type: ActionTypes.FIOSQUALIFIED_VIEW, value: input });
};
export const updateInServiceView = (dispatch, input) => {
  dispatch({ type: ActionTypes.INSERVICE_VIEW, value: input });
};
export const updateResumeCartView = (dispatch, input) => {
  dispatch({ type: ActionTypes.RESUMECART_VIEW, value: input });
};
export const updateResumeSendCartView = (dispatch, input) => {
  dispatch({ type: ActionTypes.RESUMESENDCART_VIEW, value: input });
};
export const updateDiscountMnh = (dispatch, input) => {
  dispatch({ type: ActionTypes.DISCOUNT_MOBILE_HOME, value: input });
};
export const processSaveCartInvoked = (dispatch, input) => {
  dispatch({ type: ActionTypes.PROCESSSAVECART_INVOKED, value: input });
};

export const updateNewService = (dispatch, input) => {
  dispatch({ type: ActionTypes.NEW_SERVICE, value: input });
};
export const restoreFiosDefaults = (dispatch) => {
  dispatch({ type: ActionTypes.RESTOREFIOS_DEFAULTS });
};
export const updateSigninView = (dispatch, input) => {
  dispatch({ type: ActionTypes.SIGNIN_VIEW, value: input });
};
export const updateSigninVerificationView = (dispatch, input) => {
  dispatch({ type: ActionTypes.SIGNIN_VERIFICATION_VIEW, value: input });
};
export const updateSigninMHBasicPhoneVerificationView = (dispatch, input) => {
  dispatch({ type: ActionTypes.SIGNIN_BASIC_PHONE_VERIFICATION_VIEW, value: input });
};
export const updateSigninSuccess = (dispatch, input) => {
  dispatch({ type: ActionTypes.SIGNIN_SUCCESS, value: input });
};
export const enableVerifyButton = (dispatch, input) => {
  dispatch({ type: ActionTypes.ENABLE_VERIFY_BUTTON, value: input });
};
export const updateMHPhoneInput = (phoneNo) => ({ type: ActionTypes.MH_MTNUMBER, value: phoneNo });
export const updateMHZipcodeInput = (ZipCodeValue) => ({ type: ActionTypes.MH_ZIPCODE, value: ZipCodeValue });
export const updateMHEligble = (mhEligible) => ({ type: ActionTypes.MH_ELIGIBLE, value: mhEligible });
export const updateVerificationCodeInput = (VerificationCodeValue) => ({ type: ActionTypes.MH_VERIFICATIONCODE, value: VerificationCodeValue });
export const validateApiCount = (dispatch, input) => {
  dispatch({ type: ActionTypes.API_COUNT, value: input });
};
export const igVerificationStarted = (dispatch, input) => {
  dispatch({ type: ActionTypes.IG_VERIFICATION_START, value: input });
};
export const updateValidateResponse = (response) => ({ type: ActionTypes.VALIDATE_RESPONSE, value: response });
export const updateVendorDetails = async (value, dispatch) => {
  dispatch({ type: ActionTypes.VENDOR_DETAILS, value });
};

export const setReferralUrl = (dispatch, value) => {
  dispatch({ type: ActionTypes.SET_REFERRAL_URL, value });
};

export const setBrowserInfo = (visit_id, visitor_id) =>
  new Promise((resolve) => {
    try {
      const url = apiUrl().setBrowserInfo;
      const data = { visitid: visit_id, visitorid: visitor_id };
      if (!checkTokenValidity()) {
        genarateToken()
          .then((resp) => {
            if (resp.status === 200) {
              callingApi(url, MethodType.POST, data, '', { authToken: true })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  resolve(error);
                  logMetrics('setBrowserInfo', error.message, 'Error');
                });
            }
          })
          .catch((error) => {
            logKafka('ERROR', 'setBrowserInfo', error, getErrorLogUrl, 'applogs');
            logMetrics('setBrowserInfo', error.message, 'Error');
          });
      } else {
        callingApi(url, MethodType.POST, data, '', { authToken: true })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            logMetrics('setBrowserInfo', error.message, 'Error');
            resolve(error);
          });
      }
    } catch (error) {
      catchJSException('LOOPQUAL2.0', 'setBrowserInfo', error);
      logMetrics('setBrowserInfo', error.message, 'Error');
    }
  });
