/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { connect } from 'react-redux';
import { Icon } from '@vds/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AutoComplete from '../common/AutoComplete';
import '../../assets/css/addressLookup.css';
import * as action from './actions.js';
import { restoreUnitDefaultsStore } from '../units-lookup/actions';
import { dataTrack, dataTrackwithDetail } from '../../utils/vzdl';
import { openViewVzTag, sendDataVzTagErrorMessage } from '../../utils/unifiedVzTag';
import { logMetrics } from '../../services/logService';
import Location from '../location';
import * as modalAction from '../common/modal/action';
import { updateHelpView } from '../address-qualification/actions';
import { setStreetError, sameAddressError, invalidAddressError, setStreetGeoError } from './actions';
import { UnitLookUp as Action } from '../../constants/actionTypes';
import { Flowtype } from '../../constants/common.js';
import { updateAutoGeo } from '../loop-qual-app/actions.js';

const ReskinText = styled.div`
  font-size: 0.6875rem !important;
  line-height: 20px;
  font-weight: 400 !important;
  font-family: 'Verizon-NHG-eTX';
`;
const Space = styled.div`
  @media (max-width: 767px) {
    padding-top: 0px;
  }
`;
const LoopQualificationWrapper = styled.div`
  .AddressParent {
    label {
      color: #000 !important;
    }
  }
  #support-drawer {
    svg {
      path {
        fill: #000 !important;
      }
    }
  }
  label {
    &#streeError {
      color: #000 !important;
    }
  }
`;
class AddressLookup extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoader: false,
    };
  }

  componentDidMount = () => {
    // const streetInput = document.querySelector('#streetaddress');
    // if (streetInput && this.props.streetInput === '') {
    //   streetInput.focus();
    // }
    if (!window.vzlqw?.fromOnClick && !this.props.customerStreetDetails && this.props.streetInput) {
      this.props.dispatch(action.updateStreetInput(''));
      this.props.dispatch(action.resetAddressCollection());
    }
    action.showLQCta(this.props.dispatch, false);
  };

  onStreetSelect = (value, item) => {
    // if (this.props.loopQualApp.lq4 && value === 'Need help?') {
    //   modalAction.openModalWindow('LQAPP', this.props.dispatch);
    //   openViewVzTag('lq help');
    //   updateHelpView(this.props.dispatch, true);
    //   return true;
    // }

    if (value.toLowerCase() === 'show more results') {
      setTimeout(() => {
        const listItemWrapper = document.querySelectorAll('.reskin-menu-list-item');
        if (listItemWrapper.length) {
          listItemWrapper[11].classList.add('menuHighlighted');
          listItemWrapper[11].id = 'selectedOption';
          const a = listItemWrapper[11].querySelector('a');
          if (a) {
            a.classList.add('item-highlighted');
          }
          listItemWrapper[11].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 200);
    }

    if (value.toLowerCase() === 'show less results') {
      setTimeout(() => {
        const listItemWrapper = document.querySelectorAll('.reskin-menu-list-item');
        if (listItemWrapper.length) {
          listItemWrapper[listItemWrapper.length - 1].classList.add('menuHighlighted');
          listItemWrapper[listItemWrapper.length - 1].id = 'selectedOption';
          const a = listItemWrapper[listItemWrapper.length - 1].querySelector('a');
          if (a) {
            a.classList.add('item-highlighted');
          }
          listItemWrapper[listItemWrapper.length - 1].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 200);
    }

    if (value.toLowerCase().startsWith('show')) {
      action.showFullResponse(
        this.props.dispatch,
        value === 'Show more results' ? this.props.addressLookup.fullSuggestion : this.props.addressLookup.halfSuggestion,
      );
      const streetInput = document.querySelector('#streetaddress');
      setTimeout(() => streetInput.click(), 10);
      return true;
    }

    this.setState({ isLoader: true });
    this.props.dispatch(action.updateStreetInput(value));
    this.props.dispatch(setStreetError(false));
    this.props.dispatch(setStreetGeoError(false));
    this.props.dispatch(sameAddressError(false));
    this.props.dispatch(invalidAddressError(false));
    this.props.dispatch(action.streetEmptyError(false));
    this.setState({ isLoader: false });
    this.props.dispatch(action.resetAddressCollection());
    if (item && item.locusID) {
      action.getSHA256Val(this.props.dispatch, item, 'street');
      logMetrics('onStreetSelectLocus', `${JSON.stringify(item)}`, 'Info');
    } else if (item && item.ntasAddrID) {
      action.getSHA256Val(this.props.dispatch, item, 'ntas');
      logMetrics('onStreetSelectNtas', `${JSON.stringify(item)}`, 'Info');
    } else {
      this.props.dispatch(action.onStreetSelection(item));
      logMetrics('onStreetSelectResponse', 'onStreetSelect', 'Info');
      logMetrics('onStreetSelect', `StreetResponse: ${JSON.stringify(item)}`, 'Info');
    }
  };

  speechonChange = (value) => {
    if (value) {
      this.props.dispatch(action.updateStreetInput(value));
      action
        .onStreetSearch(value, this.props.dispatch)
        .then(() => {
          this.setState({ isLoader: false });
        })
        .catch(() => {
          this.setState({ isLoader: false });
        });
      if (this.props.customerStreetDetails) {
        this.props.dispatch(action.resetStreetSelection());
      }
      const streetInput = document.querySelector('#streetaddress');
      if (streetInput) {
        streetInput.focus();
      }
    }
    if (this.props.addressLookup.streetGeoError) {
      sendDataVzTagErrorMessage(`We couldn't locate you. Please enter your address.`, 'lq sticky bar');
    }
  };

  needHelpClick = () => {
    modalAction.openModalWindow('LQAPP', this.props.dispatch);
    openViewVzTag('lq help');
    updateHelpView(this.props.dispatch, true); // need help view
  };

  onChange = (event) => {
    // greyRemove(); CXTDT-479011
    if (this.props.loopQualApp.flowType === 'MOVERS' && this.props.addressQualification.lqResult.mvInvAdr) {
      this.props.dispatch({ type: 'RESET_QUALIFICATION_DETAILS_LQRESULT' });
    }
    const streetAddress = /^[a-zA-Z0-9]/.test(event?.target?.value[0]) ? event?.target?.value : event?.target?.value.substr(1);
    this.props.dispatch(updateAutoGeo(false));
    this.props.dispatch(action.updateStreetInput(streetAddress));
    if (this.props.addressLookup.streetInput?.length > 1 && this.props.addressLookup.streetInput.length <= 4) {
      console.log('suppressing 15Gifts bubble - streetInput');
      if (window.__15gifts && window.__15gifts.leap) {
        window.__15gifts.leap.suppressEngagementBubble();
        console.log('15Gifts suppressEngagementBubble');
      }
    }
    this.props.dispatch({ type: Action.UPDATE_UNIT_REQUIREMENT, value: false });
    this.props.dispatch(setStreetError(false));
    this.props.dispatch(setStreetGeoError(false));
    this.props.dispatch(sameAddressError(false));
    this.props.dispatch(invalidAddressError(false));
    this.props.dispatch(action.streetEmptyError(false));
    const prevAddress = this.props.addressLookup.streetInput ? this.props.addressLookup.streetInput : '';
    const sameAddressOnEdit = prevAddress ? prevAddress === event.target.value : false;
    const isinHomeAvailabiliytPage = window.location.pathname.includes('inhome/checkavailability');
    if (this.props.loopQualApp && this.props.loopQualApp.lq4 && !isinHomeAvailabiliytPage && (event.target.value === '' || !sameAddressOnEdit)) {
      modalAction.closeModalWindow(this.props.dispatch);
      setTimeout(() => {
        const streetInput = document.querySelector('#streetaddress');
        if (streetInput) {
          streetInput.focus();
        }
      }, 1000);
    }
    // this.setState({isLoader : true})
    action
      .onStreetSearch(event.target.value, this.props.dispatch)
      .then(() => {
        this.setState({ isLoader: false });
      })
      .catch(() => {
        this.setState({ isLoader: false });
      });

    if (this.props.customerStreetDetails) {
      this.props.dispatch(action.resetStreetSelection());
    }
    if (!event.target.value) {
      restoreUnitDefaultsStore(this.props.dispatch);
    }
  };

  render() {
    const unitInputProps = { placeholder: '' }; // VZWHDE-1300 commenting placeholder
    const { customerStreetDetails, addressCollection, streetInput, errorMessage, staticContent, noSuggestedAddress } = this.props;
    let validateMsg = !this.props.fromSticky && !this.props.addressLookup.streetGeoError && (
      <ReskinText color="#6f7171" id="street-helper-text" aria-label={staticContent.STREET_INPUT_HELPER_TEXT}>
        {staticContent.STREET_INPUT_HELPER_TEXT}
      </ReskinText>
    );

    if (customerStreetDetails === null && streetInput !== '' && !this.props.addressLookup.streetGeoError) {
      if (noSuggestedAddress) {
        validateMsg = (
          <ReskinText color="#6f7171" id="street-helper-text">
            {staticContent.STREET_INPUT_ERROR_TEXT} {staticContent.STREET_INPUT_HELPER_TEXT}
          </ReskinText>
        );
      } else if (addressCollection && addressCollection.length === 0) {
        validateMsg = <ReskinText color="#6f7171">{staticContent.STREET_INPUT_ERROR_TEXT}</ReskinText>;
      }
    } else if (customerStreetDetails && customerStreetDetails.disprawstr) {
      validateMsg = '';
    }
    return (
      <LoopQualificationWrapper className="loop-qualification full-width address-input-height" data-cs-mask>
        <div className="AddressParent">
          <div className="child address-height" style={{ float: 'left' }}>
            {this.props.fromSticky ? (
              <label htmlFor="streetaddressLabel" color={`${this.props?.colors?.color || 'white'}`} id="streetInputText">
                {this.props.lqWireline ? staticContent.STREET_INPUT_LABEL_NEW : staticContent.STREET_INPUT_LABEL}{' '}
                <button
                  type="button"
                  id="support-drawer"
                  aria-label="Street address help"
                  className={this.props?.colors?.surface || ''}
                  onClick={() => this.needHelpClick()}
                  data-track={dataTrack('link', 'need help modal click')}
                >
                  <Icon
                    id="helpIcon"
                    name="support-drawer"
                    color={`${this.props?.colors?.color || '#FFFFFF'}`}
                    size="small"
                    ariaHidden="true"
                    min-width="13px"
                  />{' '}
                </button>
              </label>
            ) : (
              <label htmlFor="streetaddressLabel">
                {this.props.loopQualApp?.flowType === Flowtype.MOVERS && !this.props.loopQualApp.isVfEc && !this.props.loopQualApp.TWS
                  ? staticContent.STREET_INPUT_LABEL_NEW1
                  : staticContent.STREET_INPUT_LABEL}
              </label>
            )}
          </div>
          <div id="geoLocationText" className="child" style={{ float: 'right', 'line-height': '1.4' }}>
            {window.sessionStorage.getItem('GeoQualBlock') !== 'Y' && !this.props.customerStreetDetails && (
              <Location
                colors={this.props?.colors}
                fromSticky={this.props.fromSticky}
                dispatch={this.props.dispatch}
                speechonChange={this.speechonChange}
                loopQualApp={this.props.loopQualApp}
              />
            )}
          </div>
        </div>
        <AutoComplete
          value={streetInput}
          items={addressCollection}
          onChange={this.onChange}
          onSelect={this.onStreetSelect}
          inputProps={{
            className: `${
              this.props.addressLookup.streetError ||
              this.props.addressLookup.sameAddressError ||
              this.props.addressLookup.streetEmptyError ||
              this.props.addressLookup.invalidAddressError ||
              this.props.addressLookup.streetGeoError
                ? 'autocompleteInput address-autocomplete autocompleteInputError'
                : 'autocompleteInput address-autocomplete'
            } ${this.props.colors?.surface || ''}`,
            'aria-label':
              streetInput === ''
                ? `${staticContent?.ERRORS?.STREET_INPUT_EMPTY} ${
                    this.props.addressLookup.streetError ? staticContent?.ERRORS?.STREET_ERROR_MVPH2 : ''
                  }`
                : `${staticContent.STREET_INPUT_LABEL}`,
            'aria-controls': 'Addressoptions',
            id: 'streetaddress',
            itemval: 'disprawstr',
            itemkey: ['locusID', 'muni'],
            datatracker:
              this.props.loopQualApp && this.props.loopQualApp.lq4
                ? dataTrackwithDetail('link', 'street address select', 'lq sticky bar')
                : dataTrack('link', 'street address select'),
            ...unitInputProps,
            type: 'text',
          }}
          typedValue={streetInput}
          success={!!(customerStreetDetails && customerStreetDetails.disprawstr)}
          showLoader={this.state.isLoader}
          loopQualApp={this.props.loopQualApp}
          addressLookupError={
            this.props.addressLookup.streetError ||
            this.props.addressLookup.sameAddressError ||
            this.props.addressLookup.streetEmptyError ||
            this.props.addressLookup.invalidAddressError ||
            this.props.addressLookup.streetGeoError
          }
          addressLookup={this.props.addressLookup}
        />
        {this.props.addressLookup.streetGeoError ? (
          <label
            htmlFor="streetaddressLabel"
            aria-live="polite"
            color={this.props.colors?.color || (this.props.fromSticky ? '#FFFFFF' : '#000000')}
            id="streeError"
          >
            {staticContent?.ERRORS?.STREET_GEO}
          </label>
        ) : this.props.addressLookup.sameAddressError ? (
          <label
            htmlFor="streetaddressLabel"
            aria-live="polite"
            color={this.props.colors?.color || (this.props.fromSticky ? '#FFFFFF' : '#000000')}
            id="streeError"
          >
            {staticContent?.ERRORS?.SAME_ADDRESS}
          </label>
        ) : this.props.addressLookup.streetError && this.props.loopQualApp.isMvph2 ? (
          <label
            htmlFor="streetaddressLabel"
            aria-live="polite"
            color={this.props.colors?.color || (this.props.fromSticky ? '#FFFFFF' : '#000000')}
            id="streeError"
          >
            {staticContent?.ERRORS?.STREET_ERROR_MVPH2}
          </label>
        ) : this.props.addressLookup.streetError ? (
          <label
            htmlFor="streetaddressLabel"
            aria-live="polite"
            color={this.props.colors?.color || (this.props.fromSticky ? '#FFFFFF' : '#000000')}
            id="streeError"
          >
            {staticContent?.ERRORS?.STREET_ERROR}
          </label>
        ) : this.props.addressLookup.invalidAddressError ? (
          <label
            htmlFor="streetaddressLabel"
            aria-live="polite"
            color={this.props.colors?.color || (this.props.fromSticky ? '#FFFFFF' : '#000000')}
            id="streeError"
          >
            {staticContent?.ERRORS?.INVALID_ADDRESS}
          </label>
        ) : this.props.addressLookup.streetEmptyError ? (
          <label
            htmlFor="streetaddressLabel"
            aria-live="polite"
            color={this.props.colors?.color || (this.props.fromSticky ? '#FFFFFF' : '#000000')}
            id="streeError"
          >
            {staticContent?.ERRORS?.STREET_EMPTY}
          </label>
        ) : (
          <Space />
        )}
        {this.props.addressQualification.lqResult && this.props.addressQualification.lqResult.mvInvAdr ? (
          <ReskinText color="#6f7171" id="street-helper-text" aria-label={staticContent?.ERRORS?.SAMEADDRESS_MOVE_ERROR}>
            {staticContent?.ERRORS?.SAMEADDRESS_MOVE_ERROR}
          </ReskinText>
        ) : (
          <>
            {!this.props.modalOverlay.isModalOpened && this.props.loopQualApp.lq4
              ? ''
              : validateMsg &&
                !(
                  this.props.addressLookup.streetError ||
                  this.props.addressLookup.streetEmptyError ||
                  this.props.addressLookup.invalidAddressError
                ) && <span style={{ paddingBottom: '16px' }}>{validateMsg}</span>}
            {errorMessage ? (
              <p style={{ color: 'red' }} aria-live="polite">
                {errorMessage}
              </p>
            ) : (
              <></>
            )}{' '}
          </>
        )}
      </LoopQualificationWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  addressLookup: state.addressLookup,
  customerStreetDetails: state.addressLookup.customerStreetDetails,
  addressCollection: state.addressLookup.addressCollection,
  streetInput: state.addressLookup.streetInput,
  errorMessage: state.addressLookup.errorMessage,
  modalOverlay: state.modalOverlay,
  addressQualification: state.addressQualification,
  unitInputError: state.unitLookup.unitInputError,
});

export default connect(mapStateToProps)(AddressLookup);

AddressLookup.propTypes = {
  addressCollection: PropTypes.string,
  addressLookup: PropTypes.string,
  errorMessage: PropTypes.string,
  dispatch: PropTypes.string,
  staticContent: PropTypes.string,
  customerStreetDetails: PropTypes.string,
  streetInput: PropTypes.string,
  loopQualApp: PropTypes.string,
  noSuggestedAddress: PropTypes.bool,
  modalOverlay: PropTypes.object,
  addressQualification: PropTypes.string,
  fromSticky: PropTypes.bool,
  // unitInputError: PropTypes.bool,
  colors: PropTypes.any,
  lqWireline: PropTypes.any,
};
